.thong-tin-quy-hoach-page {
  padding: 40px 0;
  
  .page-header {
    display: flex;
    align-items: center;
  }

  .page-title {
    font-size: 32px;
    margin-left: 24px;
    margin-bottom: 4px;
    color: #007bff;
  }

  .button-back {
    box-shadow: none;
    background-color: #f2f2f2;

    &:hover {
      box-shadow: none;
    }
  }

  .MuiAppBar-colorPrimary {
    margin-top: 20px;
    margin-left: 0 !important;
    background-color: #e0e0e0;
    box-shadow: none;
    border-radius: 3px;
    overflow: hidden;
  }

  .MuiTabs-root {
    .MuiTabs-flexContainer {
      color: #212529;
    }

    .MuiTabs-indicator {
      background-color: #27b733;
    }

    .Mui-disabled {
      display: none;
    }

    .MuiTabs-scrollButtons {
      path {
        fill: #212529;
      }
    }
  }

  .MuiGrid-container {
    margin: 24px 0;

    .field-label {
      color: #383838;
      font-weight: 500;
    }
  }

  .MuiStepper-root {
    max-width: 200px;
    margin: 20px auto 0 !important;

    .MuiStepIcon-active,
    .MuiStepIcon-completed {
      color: #359AE1;
    }
  }

  .button-deactivate {
    color: #333;
    background-color: #ddd;
    border-radius: 3px;
    height: 40px;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 1rem;
    margin-right: 20px;
  }

  .button-active {
    color: #fff;
    background-color: #003F8E;
    border-radius: 3px;
    height: 40px;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 1rem;

    &:hover {
      background-color: #003F8E;
      opacity: 0.8;
    }
  }
  .content-right{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
  .sub-item {
    padding: 0px 13px;
    width: calc((100% - 30px));
    box-shadow: 0px 0px 5px 0px #cccccc;
    .sub-item-line {
      padding: 5px 0px;
      border-bottom: 1px dashed #e0e0e0;
      align-items: center;
      &:last-child {
        border-bottom: 0px dashed #e0e0e0;
      }
      .icon {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: black;
        margin-right: 10px;
      }
      .name {
      }
    }
    .active-sub {
      .icon {
        background-color: #27b733;
      }
      .name {
        color: #27b733;
      }
    }
  }
  .active {
    background-color: #27b733 !important;
    color: #fff !important;
    .icon {
      path {
        stroke: #fff;
      }
    }
  }
  .react-datepicker-wrapper{
    input{
      height: 50px;
      width: 100%;
      border: 0px;
    }
  }
}