.planning-provincial-wrapper{
    margin-top: 30px;
    .header-planning{
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .title{
            color: rgba(28, 64, 159, 1);
            font-size: 20px;
            font-weight: 500;
        }
    }

    .form {
        max-width: 1000px;

        .select-year {
            max-width: 100px;
        }
    }

    .no-data {
        width: 100%;
    }
}