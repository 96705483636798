.report-planning-project-wrapper{
    .title{
        font-size: 24px;
        font-weight: 500;
        color: #0D0B52;
    }
    .link{
       cursor: pointer;     
    }
    .content{
        margin-top: 15px;
        .content-right{
            .item{
                margin-top: 20px;
                .label{
                    align-items: center;
                    .color{
                        width: 10px;
                        height: 10px;
                        margin-right: 10px;
                    }
                   
                }
                .value{
                    font-weight: 500;
                    margin-right: 20px;
                }
            }
           
        }
    }
   
}