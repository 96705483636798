.listvideos-title {
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  text-transform: uppercase;
}

.first-videos-border {
  width: 500px !important;
}

.first-videos-image {
  width: 100% !important;
}

.first-videos-image img {
  width: 100% !important;
  height: 460px;
}

.fist-videos-info {
  padding-top: 20px;
  min-height: 20px;
}

.first-videos-title {
  padding-bottom: 10px;
}

.first-videos-title a {
  font-weight: bold;
  color: #1b1e21;
}

.first-videos-title a:hover {
  font-weight: bold;
}

.hot-other-videos-title {
  min-height: 30px;
  border-top: solid 1px #dddddd;
  padding-top: 10px;
  padding-bottom: 10px;
}

.hot-other-videos-title a {
  color: #1d2124;
}

.list-videos {
  min-height: 150px;
}

.list-videos .videos_title {
  height: 40px;
  font-weight: bold;
  font-size: 16px;
}

.list-videos .videos-item .videos-image {
  margin-top: 10px;
  margin-bottom: 10px;
}

.list-videos .videos-item .videos-image img {
  width: 100% !important;
  max-width: 100% !important;
  height: 160px;
}
.news_title {
  font-weight: bold;
  color:#0D0B52;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.see-more {
  margin-top: 30px;
}

.list-videos .videos-info {
  text-align: justify;
  .videos-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    white-space: unset !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: #333333;
    }
  }
  .new-date {
    margin-top: 5px;
    color: #777777;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

.list-videos .videos-info a {
  color: #1b1e21;
}

.list-videos .videos-info a:hover {
  color: #038ff4;
  text-decoration: none;
}

@media (max-width: 1360px) {
  .fist-videos-info {
    width: 96% !important;
  }
}

@media only screen and (min-width: 1361px) and (max-width: 1920px) {
  .fist-videos-info {
    width: 96.4% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .fist-videos-info {
    width: 96.4% !important;
  }
}

@media (max-width: 767px) {
  .first-videos-image img {
    height: auto !important;
  }
  .fist-videos-info {
    width: 92.4% !important;
  }
}
