.planning-provincial-wrapper{
    margin-top: 30px;
    .header-planning{
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .title{
            color: rgba(28, 64, 159, 1);
            font-size: 20px;
            font-weight: 500;
        }
        .action{
            #dropdown-autoclose-true{
                background-color: rgba(255, 255, 255, 1) !important;
                color: rgba(56, 56, 56, 1) !important;
                border: 1px solid rgba(141, 141, 141, 1) !important;
                &:focus{
                    box-shadow:none;
                }
            }
            .menu{
                // width: 100%;
            }
        }
    }
}