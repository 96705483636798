.footer-container {
    background: linear-gradient(0deg, #1c40a0, #1c40a0);
    color: white;
    padding: 15px 0;

    h5 {
        font-size: 1.125rem;
        font-family: 'Barlow Semi Condensed', sans-serif;
        font-weight: 600;
    }
}

.footer_website {}
.footer_website a {color: #FFFFFF}
.footer_website a:hover {color: #FFFFFF}
.footer-logo {
    min-height: 20px;
    margin-top: 10px;
}
.footer-logo img {
    width: 80px;
}
.footer-title {
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
}
.footer-note {
    min-height: 20px;
    margin-top: 10px;
}

#footer-note-2 {
    display: none;
}