.project-info-wrapper {
  .item-line {
    // align-items: center;
    margin-bottom: 20px;
    width: 100%;
    .MuiAutocomplete-input {
      &:first-child {
        padding-left: 15px !important;
      }
    }
    .MuiFormControl-root {
      width: calc((100% - 290px));
    }
    .input-sun-editor {
      width: calc((100% - 290px));
    }
    .MuiInputBase-root {
      padding-right: 30px !important;
      padding: 3px !important;
    }
    .MuiFormControl-fullWidth {
      width: 100% !important;
    }
    &:first-child {
      .MuiFormControl-root {
        width: 110px;
      }

      .MuiSelect-select {
        width: 50px;
      }
    }
    &:last-child {
      justify-content: center;
    }
    .MuiOutlinedInput-input {
      padding: 11.5px 14px;
    }

    .MuiButton-label {
      text-transform: initial;
      font-weight: 400;
    }
    .label {
      width: 290px;
      font-weight: 500;
      align-items: center;
    }
    .text {
      margin: 0px 10px;
      color: #888888;
    }
  }
  .button-active {
    color: #fff;
    background-color: #003f8e;
    border-radius: 3px;
    height: 40px;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 1rem;

    &:hover {
      background-color: #003f8e;
      opacity: 0.8;
    }
  }
  .button-deactivate {
    color: #333;
    background-color: #ddd;
    border-radius: 3px;
    height: 40px;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 1rem;
    margin-right: 20px;

    &:hover {
      background-color: #ddd;
      opacity: 0.8;
    }
  }
}
