.cb {clear: both !important}
.trial-box-container {
  position: absolute;
  width: 100%;
}
.trial-box {
  position: absolute;
  top: -30px;
  right: 10px;
  color: #FFFFFF;
  text-align: right;
  text-decoration: underline;
  width: 100%;
  font-style: italic;
  cursor: pointer;
}