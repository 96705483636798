.modal-user-info-create-project {
  .item-line {
    // align-items: center;
    margin-bottom: 20px;
    width: 100%;
    .MuiFormControl-root {
      width: calc((100% - 270px));
    }
    &:first-child {
      .MuiFormControl-root {
        width: 80px;
      }

      .MuiSelect-select {
        width: 50px;
      }
    }
    &:last-child {
      justify-content: center;
    }
    .MuiOutlinedInput-input {
      padding: 11.5px 14px;
    }

    .MuiButton-label {
      text-transform: initial;
      font-weight: 400;
    }
    .label {
      width: 270px;
      font-weight: 500;
      align-items: center;
    }
    .text {
      margin: 0px 10px;
      color: #888888;
    }
  }
  .button-active {
    color: #fff;
    background-color: #003f8e;
    border-radius: 3px;
    height: 40px;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 1rem;

    &:hover {
      background-color: #003f8e;
      opacity: 0.8;
    }
  }
  .button-deactivate {
    color: #333;
    background-color: #ddd;
    border-radius: 3px;
    height: 40px;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 1rem;
    margin-right: 20px;

    &:hover {
      background-color: #ddd;
      opacity: 0.8;
    }
  }
  .modal-content {
    border: 0;

    .modal-header {
      background: #1c40a0;
      color: #fff;
    }

    .modal-body {
      .img-avatar {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }

      .btn-change-avatar {
        color: #1c40a0;
      }

      .media-file {
        position: absolute;
        top: 30px;
        left: 0;
        overflow: hidden;
        height: 100% !important;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
