.change-password-wrapper{
    .item-line{
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        .MuiOutlinedInput-input{
            padding: 11.5px 14px;
            width: 600px;
        }
        .MuiButton-label{
            text-transform: initial;
            font-weight: 400;
        }
        .label{
            width: 200px;
            font-weight: 500;
            align-items: center;
        }
    }
    .button-active {
        color: #fff;
        background-color: #003F8E;
        border-radius: 3px;
        height: 40px;
        padding-left: 26px;
        padding-right: 26px;
        font-size: 1rem;
    
        &:hover {
          background-color: #003F8E;
          opacity: 0.8;
        }
      }
}