.layout-user-info {
  .container-info{
    width: 100%;
    margin: 0px;
  }
  .title {
    color: #1C409F;
    font-weight: bold;
    margin-top: 30px;
  }
  .history-system{
    color: #5F6368;
    cursor: pointer;
    margin-top: 30px;
  }
  .content {
    border: 1px solid #EAEAEA;
    border-radius: 3px;
    margin-top: 10px;
  }
  .container-history-system{
    position: absolute;
    top: 70px;
    display: none;
    right: 0px;
    width: 0px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    z-index: 99999;
    .header{
      height: 52px;
      border-bottom: 1px solid;
      align-items: center;
      padding: 0px 10px;
      .title-history{
        color: #1C409F;
        font-weight: bold;
      }
      .icon{
        cursor: pointer;
      }
    }
    .content-history{
      padding: 10px 20px 0px 20px;
      .item-history{
        margin-bottom: 10px;
        .icon-dot{
          margin-right: 10px;
        }
        .item-content{
          .date-time{
            font-weight: 500;
          }
          .description{

          }
        }
      }
    }
    .pagination-video{
      margin-top: 20px;
    }
  }
  .active-history{
    display: block;
    animation: myfirst 1s 2;
    animation-iteration-count: 1;
    width: 300px;
   
  }
  
  @keyframes myfirst {
    0%   { width: 0px;}
    100% {width: 300px;}
  }
}