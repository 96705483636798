.report-situation-wrapper{
    margin-top: 20px;
    .wrapper-title {
        font-weight: 500;
        color: #0D0B52;
        font-size: 24px;
        margin-bottom: 20px;
    }
    .content{
        .title{
            font-size: 20px;
            font-weight: 500;
        }
        .link{
           cursor: pointer;     
        }
        .content-right{
            .item{
                margin-top: 20px;
                .label{
                    align-items: center;
                    .color{
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                   
                }
                .value{
                    font-weight: 500;
                    margin-right: 20px;
                }
            }
           
        }
    }
}