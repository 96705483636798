.left-side-bar-user-info {
  width: calc((100% -20px));
  box-shadow: 0px 0px 5px 0px #cccccc;
  margin-top: 1px;
  .item {
    cursor: pointer;

    .title-side-bar {
      padding: 7px 15px 7px 7px;
      background: #f2f2f2;
      justify-content: space-between;
      font-weight: 500;
      .text {
        width: calc((100% - 50px));
      }
      .icon {
      }
    }
    .sub-item {
      padding: 0px 13px;
      .sub-item-line {
        padding: 5px 0px;
        border-bottom: 1px dashed #e0e0e0;
        align-items: center;
        &:last-child {
          border-bottom: 0px dashed #e0e0e0;
        }
        .icon {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: black;
          margin-right: 10px;
        }
        .name {
        }
      }
      .active-sub {
        .icon {
          background-color: #27b733;
        }
        .name {
          color: #27b733;
        }
      }
    }
    .active {
      background-color: #27b733 !important;
      color: #fff !important;
      .icon {
        path {
          stroke: #fff;
        }
      }
    }
  }

  .logout {
    .title-side-bar {
      color: red;
    }
  }
}
