.header-container .navbar {
  z-index: 1000;
}

.site-logo{
  position: absolute;
  text-align: center;
  left: 30px;
  z-index: 1001;
}

.site-logo img {
  position: relative;
  width: 40px;
  height: 40px;
}

.brand-name {
  height: 20px;
  text-align: center;
  position: relative;
  top: -6px;
}
.brand-name a {
  color: #FFFFFF;
  font-size: 10px;
}
.brand-name a:hover {
  color: #FFFFFF;
  font-size: 10px;
  text-decoration: none;
}

#site-menu {
  font-size: 16px;
  text-transform: uppercase;
  top: 8px;
  position: absolute;
  right: 20px;
}

#site-menu .nav-link {
  color: #FFFFFF !important;
}

#entry_do_thi_pc {
  display: block;
}
#entry_do_thi_mobile {
  display: none;
}


.dropdown-item {
  font-size: 15px !important;
}

#site-menu .dropdown-menu {
  margin-top: 14px !important;
  border-radius: 0px !important;
}

.top-bg-color {
  background-color: #E5E7F3;
}
.header-container {
  background-color: #1c40a0;
  height: 70px;
  
  .cursor-pointer {
    cursor: pointer;
    width:25%
  }
}

@media (min-width: 1400px) {
  .padding-left-menu {
    padding-left: 3rem !important;
  }
}

.modal-user-info {
  .modal-content {
    border: 0;

    .modal-header {
      background: #1c40a0;
      color: #fff;
    }

    .modal-body {
      .img-avatar {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }

      .btn-change-avatar {
        color: #1c40a0;
      }

      .media-file {
        position: absolute;
        top: 30px;
        left: 0;
        overflow: hidden;
        height: 100% !important;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}